import { BW_LANGUAGE_EN } from 'src/libs/qb-brand-web-components'

const brandConfig = {
  brandId: 'meet_the_people',
  name: 'Meet The People',
  faqEmail: 'support@qiibee.com',
  websiteUrl: 'https://meet-the-people.com',
  theme: {
    colors: {
      main: '#000000',
      accent: '#8CB010'
    }
  },
  supportedLanguages: [BW_LANGUAGE_EN],
  features: {
    disableTermsCheckbox: true
  },
  cognito: {
    region: 'eu-central-1',
    poolId: 'eu-central-1_ql9USIOuj',
    clientId: '3j8ftkfl2htiqigmupavlb4j7p',
    poolDomain: 'bwamtp.auth.eu-central-1.amazoncognito.com',
    redirectUri: 'https://meet-the-people-rewards.com/login'
  },
  gaTrackingId: 'G-1XMEJS3YZ3',
  hotjarId: '3192122'
}

export default brandConfig
